
import { defineComponent } from "vue"
import { ClockPluginKey, receiveSync, getSync } from "./clock"

export default defineComponent({
  name: "Notes",
  props: {
    documents: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      syncA: "",
      syncB: "",
    }
  },
  mounted: function() {
    console.log(this.documents())
  },
  methods: {
    logView: function (id: string) {
      console.log(`${id}:`, this.documents()[id].getView());
    },
    logClock: function(id: string) {
      console.log(`${id}:`, ClockPluginKey.getState(this.documents()[id].getView().state))
    },
    stepBack: function(id: string) {
      const view = this.documents()[id].getView()
      const tr = view.state.tr;
      ClockPluginKey.getState(view.state).stepBack(tr);
      view.dispatch(tr)
    },
    sync: function() {
      this.syncFromTo(this.syncA, this.syncB);
      this.syncFromTo(this.syncB, this.syncA);
      this.syncA = "";
      this.syncB = ""
    },
    syncFromTo: function(syncFrom: string, syncInto: string) {
      if(this.ids.includes(syncFrom) && this.ids.includes(syncInto)) {
        const fromState = ClockPluginKey.getState(this.documents()[syncFrom].getView().state);
        const intoVector = ClockPluginKey.getState(this.documents()[syncInto].getView().state).version;
        const { moments, lastSync } = getSync(fromState, intoVector)
        receiveSync(moments, this.documents()[syncInto].getView(), lastSync)
      }
    }
  }
})
