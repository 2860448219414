
import { defineComponent } from "vue";

import Notes from "@/components/conflict-free-replicated-timelines/Notes.vue";
import Document from "@/components/conflict-free-replicated-timelines/Document.vue";

export default defineComponent({
  name: "Config Free Replicated Timelines",
  components: {
    Document,
    Notes,
  },
  data() {
    return {
      ids: ["Alpha", "Bravo", "Charlie", "Delta", "Echo", "Foxtrot"],
    };
  },
  methods: {
    documents: function () {
      const docs = {} as any;
      this.ids.forEach((id: string) => {
        docs[id] = (this.$refs[id] as any)[0];
      });
      return docs;
    },
  },
});
