import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Document = _resolveComponent("Document")!
  const _component_Notes = _resolveComponent("Notes")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("article", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ids, (id) => {
        return (_openBlock(), _createBlock(_component_Document, {
          azimuth: id,
          ref_for: true,
          ref: id,
          key: id
        }, null, 8, ["azimuth"]))
      }), 128))
    ]),
    _createVNode(_component_Notes, {
      documents: _ctx.documents,
      ids: _ctx.ids
    }, null, 8, ["documents", "ids"])
  ]))
}